import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppointmentState } from './appointment.state';
import { Appointment } from '@models';

export const APPOINTMENT_STATE_NAME = 'appointment';
const selectAppointmentState = createFeatureSelector<AppointmentState>(
  APPOINTMENT_STATE_NAME
);
export const selectAppointmentCount = createSelector(
  selectAppointmentState,
  state => state.total
);
const selectAppointments = createSelector(
  selectAppointmentState,
  ({ data }) => data
);
const selectFilter = createSelector(
  selectAppointmentState,
  ({ filter }) => filter
);
const selectPagination = createSelector(
  selectAppointmentState,
  ({ pagination }) => pagination
);

const selectPaginationAndFilter = createSelector(
  selectAppointmentState,
  ({ pagination, filter }) => ({ filter, pagination })
);
const selectTotal = createSelector(
  selectAppointmentState,
  ({ total }) => total
);
const selectAppointmentById = (id: Appointment['id']) =>
  createSelector(selectAppointmentState, ({ data }) =>
    data.find(v => v.id === id)
  );
export default {
  selectFilter,
  selectAppointments,
  selectTotal,
  selectPagination,
  selectAppointmentById,

  selectPaginationAndFilter,
};
