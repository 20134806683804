import {
  ApiResponse,
  Pagination,
  Appointment,
  AppointmentPageModel,
} from '@models';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

const appointmentActions = createActionGroup({
  source: 'Appointment page',
  events: {
    'fetch Appointment': emptyProps(),
    'fetch appointment success': props<{ data: ApiResponse<Appointment[]> }>(),
    'confirm appointments': props<{
      id: Appointment['id'];
      date?: string;
      ref?: string;
    }>(),
    'archive appointment': props<{ id: Appointment['id'] }>(),
    'update filter': props<{ data: AppointmentPageModel.filter }>(),
    'update Pagination': props<{ data: Pagination }>(),
  },
});
export default appointmentActions;
